import i18next from "i18n/i18n";
import {
  ActionPointForBusiness,
  applicationStatus,
  ContactStatus,
  FAQStatus,
  PostStatus,
} from "./enum";
import { IFile } from "./interface";

export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_WIDTH_COLLAPSED = 80;
export const defaultTake = 10;

export const MIN_LENGTH_PASSWORD = 8;
export const MAX_LENGTH_PASSWORD = 25;
export const MAX_LENGTH_USERNAME = 50;

export const LIMIT_AVATAR_SIZE = 10e6;
export const LIMIT_IMAGE_SIZE = 50e6;
export const LIMIT_VIDEO_SIZE = 500e6;
export const DEFAULT_LIMIT = 100;

export const LIMIT_IMAGES_VIDEOS_COUNT = 5;

export const acceptedAvatarType = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/heic",
  "image/jfif",
];

export const acceptedDocumentsType = ["application/pdf"];

export const acceptedVideoType = [
  "video/1d-interleaved-parityfec",
  "video/3gpp",
  "video/3gpp2",
  "video/3gpp-tt",
  "video/AV1",
  "video/BMPEG",
  "video/BT656",
  "video/CelB",
  "video/DV",
  "video/encaprtp",
  "video/example",
  "video/FFV1",
  "video/flexfec",
  "video/H261",
  "video/H263",
  "video/H263-1998",
  "video/H263-2000",
  "video/H264",
  "video/H264-RCDO",
  "video/H264-SVC",
  "video/H265",
  "video/H266",
  "video/iso.segment",
  "video/JPEG",
  "video/jpeg2000",
  "video/jxsv",
  "video/mov",
  "video/mj2",
  "video/MP1S",
  "video/MP2P",
  "video/MP2T",
  "video/mp4",
  "video/MP4V-ES",
  "video/MPV",
  "video/mpeg4-generic",
  "video/nv",
  "video/ogg",
  "video/parityfec",
  "video/pointer",
  "video/quicktime",
  "video/raptorfec",
  "video/raw",
  "video/rtp-enc-aescm128",
  "video/rtploopback",
  "video/rtx",
  "video/scip",
  "video/smpte291",
  "video/SMPTE292M",
  "video/ulpfec",
  "video/vc1",
  "video/vc2",
  "video/vnd.CCTV",
  "video/vnd.dece.hd",
  "video/vnd.dece.mobile",
  "video/vnd.dece.mp4",
  "video/vnd.dece.pd",
  "video/vnd.dece.sd",
  "video/vnd.dece.video",
  "video/vnd.directv.mpeg",
  "video/vnd.directv.mpeg-tts",
  "video/vnd.dlna.mpeg-tts",
  "video/vnd.dvb.file",
  "video/vnd.fvt",
  "video/vnd.hns.video",
  "video/vnd.iptvforum.1dparityfec-1010",
  "video/vnd.iptvforum.1dparityfec-2005",
  "video/vnd.iptvforum.2dparityfec-1010",
  "video/vnd.iptvforum.2dparityfec-2005",
  "video/vnd.iptvforum.ttsavc",
  "video/vnd.iptvforum.ttsmpeg2",
  "video/vnd.motorola.video",
  "video/vnd.motorola.videop",
  "video/vnd.mpegurl",
  "video/vnd.ms-playready.media.pyv",
  "video/vnd.nokia.interleaved-multimedia",
  "video/vnd.nokia.mp4vr",
  "video/vnd.nokia.videovoip",
  "video/vnd.objectvideo",
  "video/vnd.radgamettools.bink",
  "video/vnd.radgamettools.smacker",
  "video/vnd.sealed.mpeg1",
  "video/vnd.sealed.mpeg4",
  "video/vnd.sealed.swf",
  "video/vnd.sealedmedia.softseal.mov",
  "video/vnd.uvvu.mp4",
  "video/vnd.youtube.yt",
  "video/vnd.vivo",
  "video/VP8",
  "video/VP9",
];

export const acceptedImageType = [
  "image/aces",
  "image/apng",
  "image/avci",
  "image/avcs",
  "image/avif",
  "image/bmp",
  "image/cgm",
  "image/dicom-rle",
  "image/dpx",
  "image/emf",
  "image/example",
  "image/fits",
  "image/g3fax",
  "image/gif",
  "image/heic",
  "image/heic-sequence",
  "image/heif",
  "image/heif-sequence",
  "image/hej2k",
  "image/hsj2",
  "image/jls",
  "image/jp2",
  "image/jpeg",
  "image/jpg",
  "image/jph",
  "image/jphc",
  "image/jpm",
  "image/jpx",
  "image/jxr",
  "image/jxrA",
  "image/jxrS",
  "image/jxs",
  "image/jxsc",
  "image/jxsi",
  "image/jxss",
  "image/jfif",
  "image/ktx",
  "image/ktx2",
  "image/naplps",
  "image/png",
  "image/prs.btif",
  "image/prs.pti",
  "image/pwg-raster",
  "image/svg+xml",
  "image/t38",
  "image/tiff",
  "image/tiff-fx",
  "image/vnd.adobe.photoshop",
  "image/vnd.airzip.accelerator.azv",
  "image/vnd.cns.inf2",
  "image/vnd.dece.graphic",
  "image/vnd.djvu",
  "image/vnd.dwg",
  "image/vnd.dxf",
  "image/vnd.dvb.subtitle",
  "image/vnd.fastbidsheet",
  "image/vnd.fpx",
  "image/vnd.fst",
  "image/vnd.fujixerox.edmics-mmr",
  "image/vnd.fujixerox.edmics-rlc",
  "image/vnd.globalgraphics.pgb",
  "image/vnd.microsoft.icon",
  "image/vnd.mix",
  "image/vnd.ms-modi",
  "image/vnd.mozilla.apng",
  "image/vnd.net-fpx",
  "image/vnd.pco.b16",
  "image/vnd.radiance",
  "image/vnd.sealed.png",
  "image/vnd.sealedmedia.softseal.gif",
  "image/vnd.sealedmedia.softseal.jpg",
  "image/vnd.svf",
  "image/vnd.tencent.tap",
  "image/vnd.valve.source.texture",
  "image/vnd.wap.wbmp",
  "image/vnd.xiff",
  "image/vnd.zbrush.pcx",
  "image/webp",
  "image/wmf",
  "image/emf",
  "image/wmf",
];

export const contentOfInquiry: any = {
  posting_request: "掲載依頼",
  document_request: "資料請求",
  other: "その他",
};

export const contact: any = {
  email: "メールアドレス",
  phone: "電話番号",
};

export const statusFilterPost = [
  {
    title: "全て",
    status: null,
  },
  {
    title: "完了",
    status: PostStatus.COMPLETE,
  },
  {
    title: "募集中",
    status: PostStatus.RECRUITING,
  },
  {
    title: "採用済",
    status: PostStatus.RECRUITED,
  },
  {
    title: "キャンセル",
    status: PostStatus.CANCEL,
  },
  {
    title: "締め切り",
    status: PostStatus.EXPIRED,
  },
];

export const statusFilterUser = [
  {
    title: "全て",
    status: null,
  },
  {
    title: "完了",
    status: PostStatus.COMPLETE,
  },
  {
    title: "募集中",
    status: PostStatus.RECRUITING,
  },
  {
    title: "採用済",
    status: PostStatus.RECRUITED,
  },
  {
    title: "キャンセル",
    status: PostStatus.CANCEL,
  },
  {
    title: "締め切り",
    status: PostStatus.EXPIRED,
  },
];

export const GuaranteeStatus = {
  GUARANTEE: true,
  NO_GUARANTEE: false,
};

export const satisfactionLevel = {
  satisfied: "satisfied",
  unsatisfied: "unsatisfied",
};

export const PickUpRequest = [
  {
    id: 1,
    value: "希望",
  },
  {
    id: 2,
    value: "不要",
  },
  {
    id: 3,
    value: "どちらでも",
  },
];

export const statusFilterContact = [
  {
    title: "全て",
    status: null,
  },
  {
    title: "未対応",
    status: ContactStatus.OPEN,
  },
  {
    title: "対応済",
    status: ContactStatus.DONE,
  },
];

export const isTypeVideoFile = (file: IFile) => {
  if (acceptedVideoType.includes(file.type)) {
    return true;
  } else return false;
};

export const TooltipGroup = {
  candidate_register: "candidate_register",
  candidate_review_list: "candidate_review_list",
  candidate_edit_profile: "candidate_edit_profile",
  candidate_create_review: "candidate_create_review",
  business_create_recruitment_post: "business_create_recruitment_post",
  business_create_review: "business_create_review",
  business_candidate_review_list: "business_candidate_review_list",
  business_edit_profile: "business_edit_profile",
  business_notification_setting: "business_notification_setting",
};

export const statusTooltip = {
  shown: "shown",
  hidden: "hidden",
};

export const titleTooltip: any = {
  panelId: "パネル表示希望",
  files: "画像/動画 バナー",
  title: "レビュー",
  avatar: "アイコン設定",
  tagIds: "タグ",
  isGuaranteedMinimum: "最低保証",
  name: "店舗名",
  industryId: "業種",
  prefectureId: "地域",
  desiredLocation: "住所",
  emailNotificationSetting: "メールアドレスへの通知",
};
export const modeRevenueDashboard = [
  {
    title: "年間",
    status: "month",
  },
  {
    title: "日毎",
    status: "date",
  },
];

export const DEFAULT_LIST_BACKGROUND = [
  "#87D6EA",
  "#F971A5",
  "#F3CD0A",
  "#47985E",
];

export const statusFilterFAQ = [
  {
    title: "全て",
    status: null,
  },
  {
    title: "表示",
    status: FAQStatus.SHOWN,
  },
  {
    title: "非表示",
    status: FAQStatus.HIDDEN,
  },
];

export const planType = {
  trial: "trial",
  paid: "paid",
};

export const linkUserPage = "/users/detail/";
export const linkStorePage = `${process.env.REACT_APP_WEB_USER_DOMAIN}/store/`;

export const dataDate = [
  { id: 1, title: "月", key: "isMonday" },
  { id: 2, title: "火", key: "isTuesday" },
  { id: 3, title: "水", key: "isWednesday" },
  { id: 4, title: "木", key: "isThursday" },
  { id: 5, title: "金", key: "isFriday" },
  { id: 6, title: "土", key: "isSaturday" },
  { id: 7, title: "日", key: "isSunday" },
];

export const dataDateDefault: any = {
  isMonday: false,
  isTuesday: false,
  isWednesday: false,
  isThursday: false,
  isFriday: false,
  isSaturday: false,
  isSunday: false,
};

export const listOptionStatusApplication = [
  {
    id: 1,
    label: i18next.t("status.applying"),
    value: applicationStatus.APPLYING,
  },
  {
    id: 1,
    label: i18next.t("status.requestApply"),
    value: applicationStatus.REQUEST_APPLY,
  },
  {
    id: 2,
    label: i18next.t("status.rejected"),
    value: applicationStatus.REJECTED,
  },
  {
    id: 3,
    label: i18next.t("status.applyingCancelled"),
    value: applicationStatus.APPLYING_CANCELLED,
  },
  {
    id: 4,
    label: i18next.t("status.hired"),
    value: applicationStatus.HIRED,
  },
  {
    id: 5,
    label: i18next.t("status.hireCancelled"),
    value: applicationStatus.HIRED_CANCELLED,
  },
  {
    id: 6,
    label: i18next.t("status.completed"),
    value: applicationStatus.COMPLETED,
  },
  {
    id: 7,
    label: i18next.t("status.shopCancelled"),
    value: applicationStatus.SHOP_CANCELLED,
  },
  {
    id: 8,
    label: i18next.t("status.noAction"),
    value: applicationStatus.NO_ACTION,
  },
];

export const listColorStatus = {
  [applicationStatus.APPLYING]: "#b3cefb",
  [applicationStatus.REQUEST_APPLY]: "#93c47c",
  [applicationStatus.REJECTED]: "#bfbfbf",
  [applicationStatus.APPLYING_CANCELLED]: "#bfbfbf",
  [applicationStatus.HIRED]: "#fbdad7",
  [applicationStatus.HIRED_CANCELLED]: "#bfbfbf",
  [applicationStatus.COMPLETED]: "#d1f1da",
  [applicationStatus.SHOP_CANCELLED]: "#bfbfbf",
  [applicationStatus.NO_ACTION]: "#ff0000",
};

export const listInvoiceStatus = {
  [ActionPointForBusiness.RECRUITMENT]: "#b3cefb",
  [ActionPointForBusiness.TRANSFER_CANDIDATE]: "#bfbfbf",
  [ActionPointForBusiness.QUESTION]: "#bfbfbf",
  [ActionPointForBusiness.LINE]: "#fbdad7",
  [applicationStatus.HIRED_CANCELLED]: "#bfbfbf",
  [applicationStatus.COMPLETED]: "#d1f1da",
  [applicationStatus.SHOP_CANCELLED]: "#bfbfbf",
  [applicationStatus.NO_ACTION]: "#ff0000",
};

export const invoiceStatusList = {
  [ActionPointForBusiness.RECRUITMENT]: {
    id: ActionPointForBusiness.RECRUITMENT,
    title: "採用",
    color: "#f971a5",
    border: 0,
    textColor: "white",
  },
  [ActionPointForBusiness.LINE]: {
    id: ActionPointForBusiness.LINE,
    title: "SNS",
    color: "#43D640",
    border: 0,
    textColor: "white",
  },
  [ActionPointForBusiness.QUESTION]: {
    id: ActionPointForBusiness.QUESTION,
    title: "質問",
    color: "white",
    border: 2,
    textColor: "#8BD4E6",
  },
  [ActionPointForBusiness.TRANSFER_CANDIDATE]: {
    id: ActionPointForBusiness.TRANSFER_CANDIDATE,
    title: "候補",
    color: "#f971a5",
    border: 0,
    textColor: "white",
  },
};
