export enum COMMON_STATUS {
  ALL = "all",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum ButtonType {
  DEFAULT = "default",
  OUTLINE = "outline",
  ACTION = "action",
}

export enum ETabDetailsRecruitment {
  APPLIED = "APPLIED",
  RECRUITED = "RECRUITED",
  EVALUATE = "EVALUATE",
}

export enum QueryKey {
  LIST_STORE_WAIT_APPROVE = "listStoreWaitApprove",
  LIST_STORE = "listStore",
  DETAIL_STORE = "detailStore",
  LIST_POST_MANAGE = "listPostManage",
  DETAIL_POST = "detailPost",
  LIST_TREATMENT = "listTreatment",
  LIST_USER_MANAGE = "listUserManage",
  DETAIL_USER = "detailUser",
  COUNT_REVIEW_USER = "countReviewUser",
  REVIEW_USER = "reviewUser",
  LIST_BUG_MANAGEMENT = "listBugManagement",
  BUG_DETAIL = "bugDetail",
  CONTACT_DETAIL = "contactDetail",
  LIST_CUPS = "listCupsKey",
  LIST_EXPERIENCE = "listExperienceKey",
  LIST_EXPERIENCE_GROUP = "listExperienceGroupKey",
  DETAILS_EXPERIENCE_GROUP = "detailsExperienceGroupKey",
  LIST_PICK_UP_REQUESTS = "listPickupRequestsKey",
  LIST_EXPERIENCES = "listExperience",
  STATISTIC_BUSINESS_LOCATION = "statisticBusinessLocation",
  STATISTIC_RANGE_AGE = "statisticRangeAge",
  STATISTIC_HISTORY_SEARCH = "statisticHistorySearch",
  LIST_PREFECTURES = "listPrefectures",
  DETAILS_PREFECTURES = "detailsPrefectures",
  LIST_DISTRICTS = "listDistricts",
  DETAILS_DISTRICTS = "detailsDistricts",
  LIST_MUNICIPALITIES = "listMunicipalities",
  LIST_INDUSTRY_GROUPS = "listIndustryGroups",
  DETAILS_INDUSTRY_GROUP = "detailsIndustryGroup",
  LIST_INDUSTRIES = "listIndustries",
  LIST_PANELS = "listPanels",
  LIST_APPLICATION_DEADLINES = "listApplicationDeadlines",
  LIST_TREATMENTS = "listTreatments",
  LIST_TAGS_USER = "listTagsUser",
  LIST_TAGS_STORE = "listTagsStore",
  LIST_SURVEY_QUESTIONS = "listSurveyQuestions",
  LIST_INDUSTRY_ANALYTIC = "listIndustryAnalytic",
  PROFILE = "profile",
  DETAIL_RESPONSE_CAMPAIGN = "detailRepsonseCampaign",
  POPUP = "POPUP",
  REPORT_LIST = "reportList",
  REPORT_DETAIL = "reportDetail",
  LIST_POPUP = "listPopup",
  LIST_USER = "listUser",
  LIST_BUSINESS_GROUPS = "listBusinessGroups",
  LIST_INVOICE = "listInvoice",
  LIST_INVOICE_DETAIL = "listInvoiceDetail",
  LIST_INVOICE_AGENCY = "listInvoiceAgency",
  LIST_INVOICE_DETAIL_AGENCY = "listInvoiceDetailAgency",
}

export enum BusinessType {
  UNCONFIRMED = "unconfirmed",
  CONFIRMED = "confirmed",
}

export enum DebounceTime {
  DEFAULT = 500,
}

export enum FormatTime {
  DATE_TIME = "DD/MM/YYYY HH:mm",
  DATE = "DD/MM/YYYY",
  DATE_REVERSE = "YYYY/MM/DD",
  DATE_JAPANESE = "YYYY年MM月DD日",
}

export enum TabDetailStore {
  TAB_INFO = "1",
  TAB_REVIEWS = "2",
  TAB_PLAN = "3",
  TAB_ANALYSIS = "4",
  TAB_SCHEDULE = "5",
}

export enum DateOption {
  START_DATE = 1,
  END_DATE = 2,
}

export enum MoodType {
  SAD = 1,
  GOOD = 2,
}

export enum StaffStatus {
  LOCK = "inactive",
  OPEN = "active",
}

export enum UpdateType {
  CREATE = "add",
  UPDATE = "update",
}

export enum QuestionType {
  TEXT = "text",
  RADIO = "radio",
}

export enum NotiType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export enum PaymentResponseCampaignStatus {
  PAID = "paid",
  UNPAID = "unpaid",
}

export enum CampaignStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
}

export enum ApproveType {
  APPROVE = "approve",
  TRANSFER = "transfer",
}

export enum PaymentType {
  PAYPAY = "paypay",
  TRANSFER = "transfer",
}

export enum StaffAccountPermissionType {
  ADD = 1,
  DETAIL = 2,
  EDIT = 3,
  DELETE = 4,
  LOCK_ACCOUNT = 5,
  OPEN_ACCOUNT = 6,
  CHANGE_PASSWORD = 7,
}

export enum PostStatus {
  COMPLETE = "completed",
  RECRUITING = "recruiting",
  RECRUITED = "adopted",
  CANCEL = "cancelled",
  EXPIRED = "deadline",
}

export enum StatusPost {
  PUBLISHED = "published",
  CONFIRMED = "confirmed",
  CANCEL = "cancelled",
}

export enum TreatmentType {
  PRIVATE_ROOM_WAITING = 1,
  SHORT_TIME_OK = 2,
  PAYMENT_TRANSPORT_EXPENSE = 3,
  NO_TRAINING = 4,
  TATTOO_SCRATCHES = 5,
  INEXPERIENCED_PERSON_WELCOME = 6,
  REPEAT_WELCOME = 7,
  SHUTTLE_SERVICE_AVAILABLE = 8,
}

export enum UnitType {
  AGE = 1,
  HEIGHT = 2,
  WEIGHT = 3,
  PERCENT = 4,
}

export enum TabNotiManage {
  SITE_USER_NOTI = "1",
  SITE_STORE_NOTI = "2",
}

export enum DetailRowType {
  STRING = "string",
  STATUS = "status",
}

export enum DurationType {
  ALL = "all",
}

export enum EFormStore {
  CREATE = 1,
  UPDATE = 2,
  CONFIRM = 3,
}
export enum EFormUser {
  CREATE = 1,
  UPDATE = 2,
}

export enum ELocalStorageKey {
  USERNAME = "username",
  ROLE_TYPE = "roleType",
  ROLE_ACCOUNT_ID = "roleAccountId",
  ROLE_NAME = "roleName",
  IS_SUPER_ADMIN = "isSuperAdmin",
  I18 = "i18nextLng",
}

export enum WebType {
  CANDIDATE = "candidate",
  BUSINESS = "business",
}

export enum ActionModal {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export enum NotificationType {
  APPROVE_JOB_FOR_CANDIDATE = "approve_job_for_candidate",
  NEW_RECRUITMENT_POST_FOR_CANDIDATE_SUBSCRIBED = "new_recruitment_post_for_candidate_subscribed",
  NEW_BOX_CHAT_FOR_CANDIDATE = "new_box_chat_for_candidate",
  STORE_REVIEW_CANDIDATE = "store_review_candidate",
  REPORT_FOR_CANDIDATE = "report_for_candidate",
  REMIND_CANDIDATE_REVIEW_STORE = "remind_candidate_review_store",
  BLOCK_CANDIDATE = "block_candidate",
  NOT_PASS_RECRUITMENT_POST_FOR_CANDIDATE = "not_pass_recruitment_post_for_candidate",
  RECRUITMENT_POST_APPLY_DELETED_FOR_CANDIDATE = "recruitment_post_apply_deleted_for_candidate",
  NEW_BOX_CHAT_FOR_STORE = "new_box_chat_for_store",
  CANDIDATE_APPLIED_RECRUITMENT_POST = "candidate_applied_recruitment_post",
  CANDIDATE_CANCEL_CALENDAR = "candidate_cancel_calendar",
  REMIND_STORE_REVIEW_CANDIDATE = "remind_store_review_candidate",
  CANDIDATE_REVIEW_STORE = "candidate_review_store",
  CANDIDATE_REACT_STORE_PROFILE = "candidate_react_store_profile",
  BLOCK_STORE = "block_store",
  EXTEND_PLAN_FOR_STORE = "extend_plan_for_store",
  PLAN_EXPIRED_FOR_STORE = "plan_expired_for_store",
}
export enum FeatureManagement {
  EMPLOYER_LIST_MANAGEMENT = "employer_list_management",
  EMPLOYER_ROLE_MANAGEMENT = "employer_role_management",
  USER_ACCOUNT_MANAGEMENT = "user_account_management",
  STORE_ACCOUNT_MANAGEMENT = "store_account_management",
  APPROVAL_STORE_ACCOUNT = "approval_store_account",
  POSTS_MANAGEMENT = "posts_management",
  NOTIFICATION = "notification",
  EDIT_NOTIFICATION = "edit_notification",
  REQUEST_PLAN = "request_plan",
  LIST_PLAN = "list_plan",
  CONTRACT_MANAGEMENT = "contract_management",
  BUG_MANAGEMENT = "bug_management",
  SETTING = "setting",
  MASTER_DATA = "master_data",
}

export enum Ability {
  VIEW = "view",
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  ON_OFF = "on_off",
  CREATE_PAYMENT = "create_payment",
  APPROVAL = "approval",
}

export enum ContactType {
  CONTACT = "contact",
  BUG = "bug",
}

export enum ContactSite {
  USER = "user",
  STORE = "store",
}

export enum ContactStatus {
  OPEN = "open",
  DONE = "done",
}

export type TMasterDataStatus = "shown" | "hidden";

export type Roles = "admin" | "staff" | "accounting" | "sales";

export enum StatusBlog {
  SHOWN = "shown",
  HIDDEN = "hidden",
}

export enum EMasterDataModalType {
  NORMAL = "normal",
  TREATMENT = "treatment",
  SURVEY = "survey",
}

export enum EGeneralSettings {
  BUSINESS_HOURS = "business_hours",
  BANNERS = "banners",
  RADIUS = "radius",
  BLOG = "blog",
}
export enum FAQSite {
  USER = "user",
  STORE = "store",
}

export enum FAQType {
  USER = "candidate",
  STORE = "business",
}

export enum FAQStatus {
  SHOWN = "shown",
  HIDDEN = "hidden",
}

export enum EQuestionSurvey {
  RADIO = "radio",
  CHECKBOX = "checkbox",
}

export enum GeneralDataType {
  BUSINESS_HOURS = "business_hours",
  BANNERS = "banners",
  RADIUS = "radius",
  BLOG = "blog",
  CANDIDATE_BLOG = "candidate_blog",
}

export enum EOrderByFavoriteStores {
  AVG_ESTIMATED_SALARY = "avgEstimatedSalary",
  POPULARITY = "popularityLevel",
  SATISFIED_REVIEW_TOTAL = "satisfiedReviewTotal",
  REVIEW_TOTAL = "reviewTotal",
  FAVORITE_TOTAL = "favoriteTotal",
}

export enum EOrderDirection {
  DESC = "DESC",
  ASC = "ASC",
}

export enum RoleType {
  ADMIN = "admin",
  MANAGER = "manager",
  SALE = "sale",
  ACCOUNTING = "accounting",
  STAFF = "staff",
}

export enum PlanStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum TransferType {
  REGULAR = "regular",
  FAST = "fast",
}

export enum QueryParamsKey {
  KEYWORD = "keyword=asdasdasd&plan_id=asdasdasd",
  PLAN_ID = "plan_id",
  INDUSTRY_IDS = "industry_ids",
  PREFECTURE_IDS = "prefecture_ids",
  STATUS_DONATE = "status-donate",
}

export enum ReportType {
  ABSENT = "absent",
  LATE = "late",
}

export enum SiteType {
  CANDIDATE = "candidate",
  EMPLOYER = "employer",
  BOTH = "both",
}

export enum SocketEvent {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
}

export enum activeType {
  active = "active",
  off = "off",
}

export enum CandidateRankingConfig {
  NONE = "none", // not any file
  WHITE = "white", // upload 10 file and exp 0 ~ 299
  SILVER = "silver", //exp 300 ~ 599
  GOLD = "gold", //exp 600 ~ 1199
  DIAMOND = "diamond", //exp 1200 ~ 2399
  PRINCESS = "princess", //exp 2400 ~ infinity
}

export enum packagePlan {
  AA = "AA",
  A = "A",
  S = "S",
  SS = "SS",
  B = "B",
}

export enum popupType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum applicationStatus {
  APPLYING = "applying",
  REQUEST_APPLY = "request_apply",
  REJECTED = "rejected",
  APPLYING_CANCELLED = "applying_cancelled",
  HIRED = "hired",
  HIRED_CANCELLED = "hire_cancelled",
  COMPLETED = "completed",
  SHOP_CANCELLED = "store_cancelled",
  NO_ACTION = "no_action",
}

export enum PrimaryIdType {
  PASSPORT = 1,
  CARD_ID = 2,
}

export enum ActionPointForBusiness {
  RECRUITMENT = 1,
  QUESTION = 2,
  LINE = 3,
  TRANSFER_CANDIDATE = 4,
}

export enum LanguageType {
  JA = "ja",
  EN = "en",
  KEY = "cimode",
  VI = "vi",
  KO = "ko",
}

export enum SensitiveWordForConversationType {
  WORD = "word",
  REGEX = "regex",
}
