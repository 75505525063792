export const listNotificationKey = "listNotification";
export const notificationDetail = "notificationDetail";
export const listNotificationTemplateKey = "listNotificationTemplate";
export const listPlansKey = "listPlans";
export const listPlanRequestsKey = "listPlanRequests";
export const planDetailsKey = "planDetails";
export const listStaffKey = "listStaffKey";
export const listRolesKey = "listRolesKey";
export const listRolesForDropdownKey = "listRolesForDropdownKey";
export const detailRoleKey = "detailKey";
export const listFormManagement = "listFormManagement";
export const listPlansHistoryOfStoreKey = "listPlansHistoryOfStore";
export const listReviewsOfStoreOfStoreKey = "listReviewsOfStoreOfStore";
export const scoreReviewStoreKey = "scoreReviewStore";
export const generalSettingsKey = "generalSettings";
export const generalCandidateBlogSettingsKey = "generalCandidateBlogSettings";
export const listForbiddenWordKey = "listForbiddenWord";
export const listForbiddenWordForChatKey = "listForbiddenWordForChat";
export const listForbiddenRegexForChatKey = "listForbiddenRegexForChat";
export const listCheckboxSettingsKey = "listCheckboxSettings";
export const listIndustryDropdownKey = "listIndustryDropdown";
export const listPrefectureDropdownKey = "listPrefectureDropdown";
export const statisticIndustryKey = "statisticIndustry";
export const countForSideBar = "countForSideBar";
export const listCampaignsKey = "listCampaigns";
export const responsesCampaignKey = "responsesCampaignKey";
